<template>
  <div class="app-home">
    <AppHeader />
    <AppMain>
      <section class="app-home__top-bar">
        <CardPicker />
        <AppDescription />
      </section>
      <CardDisplay />
    </AppMain>
  </div>
</template>

<script>
import AppHeader from "~/components/AppHeader/AppHeader";
import AppMain from "~/components/AppMain/AppMain";
import AppDescription from "~/components/AppDescription/AppDescription";
import CardPicker from "~/components/CardPicker/CardPicker";
import CardDisplay from "~/components/CardDisplay/CardDisplay";
export default {
  components: {
    AppHeader,
    AppMain,
    CardPicker,
    AppDescription,
    CardDisplay
  }
}
</script>

<style lang="scss">
.app-home {
  @include background-color("neutral", 100);
  display: flex;
  flex-direction: column;
  height: 100vh;
  &__top-bar {
    display: flex;
    flex-direction: column;
    @include tablet {
      width: 400px;
    }
  }
}
</style>
