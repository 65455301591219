<template>
  <li class="card-detail" :class="`card-detail--${getFaction(card)}`">
    <h3
      class="card-detail__name"
      :class="`card-detail__name--${getFaction(card)}`"
    >
      {{ card.cardName }}
    </h3>
    <img :src="getLink(card)" class="card-detail__image" />
    <div class="card-detail__grade">
      <strong>Card Grade:</strong> {{ card.grade }}
    </div>
  </li>
</template>

<script>
export default {
  props: {
    card: Object
  },
  methods: {
    getFaction(card) {
      return card.faction.toLowerCase();
    },
    getLink(card) {
      const underscores = card.cardName.replace(/ /g, "_");
      return `https://cards.eternalwarcry.com/cards/full/${underscores}.png`;
    }
  }
};
</script>

<style lang="scss">
.card-detail {
  @include background-color("neutral", 50);
  @include elevated-item(1, "neutral");
  align-items: center;
  border-radius: 5px;
  border: solid get-color("neutral", 200) 1px;
  display: flex;
  flex-direction: column;
  height: $container-height;
  justify-content: center;
  padding: 0.25rem 0.5rem 0.5rem;
  width: $container-width;
  &__name {
    font-size: 125%;
    font-weight: bold;
    margin-bottom: 0.5rem;
    @include faction-colors;
    @include hidden;
  }
  &__image {
    height: $card-height;
  }
}
</style>
