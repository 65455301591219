<template>
  <section class="card-picker">
    <h2 class="card-picker__title">Select Eternal Card</h2>
    <Multiselect
      id="card-selector"
      class="card-picker__select"
      :multiple="true"
      :options="cardNames"
      :value="cardSelections"
      placeholder="-- Select a Card --"
      name="cardSelector"
      @input="updateCardSelections"
    />
    <AppButton class="card-picker__button" @click.native="clearCards">
      Clear
    </AppButton>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import AppButton from "~/components/AppButton/AppButton";
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapState } = createNamespacedHelpers("draftRankings");

export default {
  components: {
    AppButton,
    Multiselect
  },
  computed: {
    ...mapState({
      cardNames: state => state.cardNames,
      cardSelections: state => state.cardSelections
    })
  },
  methods: {
    ...mapMutations({
      setCardSelections: "setCardSelections"
    }),

    updateCardSelections(cards) {
      this.setCardSelections(cards);
    },
    clearCards() {
      this.setCardSelections([]);
    }
  }
};
</script>

<style lang="scss">
.card-picker {
  margin-bottom: 1rem;
  &__title {
    @include hidden;
  }
  &__select {
    max-width: 400px;
  }
  &__button {
    height: 25px;
    width: 100px;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  .multiselect {
    @include text-color("neutral", 800);
    &__placeholder {
      @include text-color("neutral", 800);
    }
    &__option {
      &--highlight {
        @include background-color("primary", 800);
        &:after {
          @include background-color("primary", 800);
        }
      }
      &--selected {
        &.multiselect__option--highlight,
        &.multiselect__option--highlight:after {
          @include background-color("red", 500);
          color: white;
        }
      }
    }
    &__tags {
      @include sunken-item(1, "neutral");
    }
    &__tag {
      @include background-color("primary", 800);
    }
    &__tag-icon {
      border-radius: 0 5px 5px 0;
      &:hover {
        @include background-color("primary", 300);
      }
      &::after {
        color: white;
      }
    }
  }
}
</style>
