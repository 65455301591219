<template>
  <component
    :is="buttonType"
    class="app-button"
    :class="[buttonSize, buttonColor]"
    :href="link"
  >
    <div class="app-button__content">
      <slot />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    link: String,
    size: String,
    color: String
  },
  computed: {
    buttonSize() {
      return `app-button--${this.$props.size}`;
    },
    buttonColor() {
      return this.$props.color ? `app-button--${this.$props.color}` : "";
    },
    buttonType() {
      const type = this.$props.link ? "a" : "button";
      return type;
    }
  }
};
</script>

<style lang="scss">
.app-button {
  @include background-color("primary", 800);
  @include rounded-item(1, "primary", 800);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: block;
  transition: all 300ms ease-in-out;
  &:hover {
    @include rounded-item(1, "primary", 300);
    @include background-color("primary", 300);
    @include color("neutral", 900);
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    word-break: break-word;
    height: 100%;
  }
  &__link {
    color: currentColor;
  }
}
</style>
