<template>
  <section class="card-display">
    <h2 class="card-display__title">Selected Cards</h2>
    <ul class="card-display__list">
      <CardDetail
        v-for="card in sortedSelections"
        :key="card.cardName"
        :card="card"
      />
    </ul>
  </section>
</template>

<script>
import CardDetail from "~/components/CardDetail/CardDetail";
import { createNamespacedHelpers } from "vuex";
import { sortBy } from "lodash";
const { mapMutations, mapState } = createNamespacedHelpers("draftRankings");

export default {
  components: {
    CardDetail
  },
  computed: {
    ...mapState({
      allCards: state => state.allCards,
      cardSelections: state => state.cardSelections
    }),
    sortedSelections() {
      const grades = {
        S: 1,
        A: 2,
        B: 3,
        C: 4,
        D: 5,
        E: 6
      };
      const allCards = this.allCards;
      const cardSelections = this.cardSelections;
      const cardObjects = allCards.filter(
        card => cardSelections.indexOf(card.cardName) >= 0
      );
      const sortedObjects = sortBy(cardObjects, [
        card => grades[card.grade],
        "rank"
      ]);
      return sortedObjects;
    }
  }
};
</script>

<style lang="scss">
.card-display {
  flex-basis: calc(200% / 3);
  padding: 1rem 0;
  @include tablet {
    padding: 0 1rem;
  }
  &__title {
    @include hidden;
  }
  &__list {
    display: grid;
    grid-gap: 2rem;
    list-style-type: none;
    grid-template-columns: repeat(auto-fit, $container-width);
    justify-content: center;
    @include tablet {
      justify-content: start;
    }
  }
}
</style>
